import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {SeatMapService} from '../../../shared/services/seat-map.service';
import {NDCApiService} from '../../../shared/services/ndc-api.service';
import {PairsPipe} from 'ngx-pipes';
import {LocalStorageService} from '../../../shared/services/local-storage.service';
import {HelpersService} from '../../../shared/services/helpers.service';
import {MetaService} from '../../../shared/services/meta.service';
import {ORDER_STATUS} from '../../../shared/constants';
import {GtmService} from "../../../shared/services/gtm.service";
import {convertPhoneIntoString} from "../../../shared/adapters/v1_2/adapters";
import { SentryService } from '../../../shared/services/sentry.service';
import {ErrorAlert} from "../../../shared/models/error-alert";
import {PaymentModuleService} from "../../../shared/services/payment-module.service";


@Component({
  selector: 'app-select-seats-modal',
  templateUrl: './select-seats-modal.component.html',
  styleUrls: ['./select-seats-modal.component.scss']
})
export class SelectSeatsModalComponent implements OnInit {

  @Input() order;
  @Input() owner;
  @Output() emitDismiss = new EventEmitter();
  @Output() emitClose = new EventEmitter();
  @Output() emitSendOrderChangeSuccess = new EventEmitter();
  @ViewChild('cardBodyContainer') cardBodyContainer: ElementRef;
  step = 1;
  paymentObject;
  paymentLoader = false;
  seatAvailability;
  segments;
  pingProceed = 0;
  selectedSeatsCopy = {};
  hasNotFullSeatsForSegment = true;
  hasFullSeatsForSegment = false;
  orderChangeSeatError: ErrorAlert = new ErrorAlert();
  orderChangeSeatWarnings = [];
  responseWarnings = [];
  seatsTotalPrice = 1.0;
  ORDER_STATUS = ORDER_STATUS;
  orderSkipPaymentForService = false;
  seatsBySegment;

  constructor(
    public seatMapService: SeatMapService,
    private helpers: HelpersService,
    private meta: MetaService,
    public ls: LocalStorageService,
    public service: NDCApiService,
    private gtmService: GtmService,
    private sentryService: SentryService,
    private paymentService: PaymentModuleService
  ) {
  }

  ngOnInit() {
    this.actionDisplaySeatAvailability();
    this.order.allowedPaymentMethods.none = !!this.order.allowedRequests.ticketed.AncillariesOnHold;
  }

  backToSelectedSeats() {
    this.setStep(2);
    this.pingProceed = 0;
  }

  setStep(step) {
    this.step = step;
  }

  getFormDataIfValid() {
    this.orderChangeSeatError = new ErrorAlert();
    this.pingProceed++;
  }

  onSeatSelect(event) {
    const countKeys = Object.keys(this.seatMapService.selectedSeats).filter(f => f.match('count'));
    this.hasNotFullSeatsForSegment = false;
    this.hasFullSeatsForSegment = false;
    const passengerCountWithoutInfant = this.order.passengers.filter(passenger => passenger.passengerType !== 'INF').length;
    let flag;
    countKeys.map(key => {
      if (!this.hasFullSeatsForSegment && +this.seatMapService.selectedSeats[key] !== 0 && +this.seatMapService.selectedSeats[key] === passengerCountWithoutInfant) {
        this.hasFullSeatsForSegment = true;
      }
      if (+this.seatMapService.selectedSeats[key] !== 0 && +this.seatMapService.selectedSeats[key] !== passengerCountWithoutInfant) {
        flag = true;
      }
    });
    this.hasNotFullSeatsForSegment = !!flag;
    this.seatsTotalPrice = this.seatMapService.getTotalPrice();
    this.updatePaymentSettings();
  }

  updatePaymentSettings() {
    if (this.owner === 'AA') {
      this.order.allowedPaymentMethods.agencyCash = !!this.hasNotFullSeatsForSegment;
      if (this.paymentService.form.get('method').value === 'agencyCash') {
        this.paymentService.form.get('method').setValue('none');
      }
    }
  }

  isInstaceOfObject(obj) {
    return obj instanceof Object;
  }

  actionDisplaySeatAvailability() {
    this.seatMapService.showSeatAvailabilityLoader = true;
    this.seatMapService.seatAvailabilityError = {};
    this.responseWarnings = [];

    const passengersCopy = [...this.order.passengers].map(item => {
      return {...item};
    });

    let body = {
      id: this.order.id,
      passengers: passengersCopy,
    };
    switch (this.ls.appVersion) {
      case 'v1.2':
        body.passengers = body.passengers.map((item) => {
          return {
            travelerReference: item.travelerReference
          };
        });
        break;
    }
    this.seatMapService.seats = [];

    this.service.sendSeatAvailability(body)
      .then((response: any) => {
        this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, 200, response);
        this.seatMapService.showSeatAvailabilityLoader = false;
        this.responseWarnings = this.helpers.getWarningsFromResponse(response);
        this.segments = response && response.segments ? response.segments : null;
        this.seatAvailability = response;
        this.seatAvailability.seatDisplay = this.seatMapService.prepareSeatDisplay(response);
        this.seatMapService.prepareSeatsPerSegment(response);
        this.seatMapService.prepareSelectedSeatsReceiver(response);
      })
      .catch((error: HttpErrorResponse) => {
        this.seatMapService.showSeatAvailabilityLoader = false;
        this.seatMapService.seatAvailabilityError = this.helpers.getError(error.status, error);
        this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, error.status, error);
        if (this.helpers.isCriticalError(error)) {
          throw error;
        }
      });
  }

  applySeats() {
    this.selectedSeatsCopy = JSON.parse(JSON.stringify(this.seatMapService.selectedSeats));
    const totalPriceOfAllSeats = this.getTotalPrice();
    this.orderSkipPaymentForService = !totalPriceOfAllSeats;
  }

  getTotalPrice() {
    const seats = this.seatsBySegment;
    let totalPriceOfAllSeats = 0;
    for (let i in this.selectedSeatsCopy) {
      Object.keys(this.selectedSeatsCopy[i]).forEach(seat => {
        if (seats[i][seat].price) {
          totalPriceOfAllSeats += seats[i][seat].price.consumer.total;
        }
      });
    }
    return totalPriceOfAllSeats;
  }

  showSelectedServices() {
    this.applySeats();
    this.setStep(2);
  }

  onEmitFormData(event) {
    if (event.isValid) {
      this.paymentObject = event.formData;
      this.requestSeats();
    }
  }

  goToPayment() {
    this.setStep(3);
  }

  getSeatsBySegments(seatsBySegment) {
    this.seatsBySegment = seatsBySegment;
  }

  requestSeats() {
    // let passengers = JSON.parse(JSON.stringify(this.order.passengers));
    let seats = [];
    let pairsPipe = new PairsPipe();
    let services = [];

    pairsPipe.transform(this.selectedSeatsCopy).map(selectedSeat => {
      let segmentID = selectedSeat[0];
      if (this.isInstaceOfObject(selectedSeat[1])) {
        pairsPipe.transform(selectedSeat[1]).map((seatObject) => {
          if (seatObject[1]) {
            let seat = this.seatMapService.allSeats[segmentID][seatObject[0]];

            let seatForRequest = {
              location: seat.location,
              segment: seat.segment,
              passenger: seatObject[1],
            };
            seats.push(seatForRequest);
          }
        });
      }
    });

    seats.map(seat => {
      let seatService = {
        type: 'seat',
        seatLocation: seat.location,
        segmentReference: seat.segment,
        travelerReference: seat.passenger,
        action: 'Create',
      };
      services.push(seatService);
    });

    const passengersCopy = [...this.order.passengers].map(item => {
      return {...item};
    });

    let body = {
      id: this.order.id,
      payment: this.paymentObject,
      services: services,
      passengers: passengersCopy,
    };
    body.passengers = body.passengers.map((item) => {
      delete item.document;
      delete item.documents;
      return item;
    });

    if (this.order.status === ORDER_STATUS.PENDING) {
      delete body.payment;
    }

    switch (this.ls.appVersion) {
      case 'v1.2':
        if (body.payment && body.payment.phone) {
          body.payment.phone = convertPhoneIntoString(body.payment.phone);
        }
        break;
    }

    this.paymentLoader = true;
    this.orderChangeSeatError = new ErrorAlert();
    this.orderChangeSeatWarnings = [];

    this.service.sendOrderChange(body, false)
      .then((res: any) => {
        this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, 200, res);
        this.gtmService.addEvent('OrderChange.AddSeats');
        this.paymentLoader = false;

        this.orderChangeSeatWarnings = this.helpers.getWarningsFromResponse(res);

        this.emitSendOrderChangeSuccess.emit(res);
        this.setStep(4);
      })
      .catch((res) => {
        this.paymentLoader = false;
        this.cardBodyContainer.nativeElement.scrollTop = 0;
        this.orderChangeSeatError = this.helpers.getError(res.status, res);
        this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, res.status, res);
        if (this.helpers.isCriticalError(res)) {
          throw res;
        }
      });
  }
}
