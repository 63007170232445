import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService} from "../../shared/services/local-storage.service";
import { SEARCH_TYPE} from '../constants';
import {Dictionary} from "../../shared/types/dictionary";

@Component({
  selector: 'app-offer-history-modal',
  templateUrl: './offer-history-modal.component.html',
  styleUrls: ['./offer-history-modal.component.scss']
})
export class OfferHistoryModalComponent implements OnInit {

  @Input() webOffersData;
  @Input() gdsOffersData;
  @Input() pnrOffersData;
  @Input() searchType;
  @Output() emitDismiss = new EventEmitter();
  @Output() emitRemoveOffer = new EventEmitter();
  @Output() emitSearchOffer = new EventEmitter();

  showColumn = {
    preferences: false,
    specialDiscounts: false,
    preset: false
  };

  SEARCH_TYPE = SEARCH_TYPE;
  objectKeys = Object.keys;
  farePreferences = Dictionary.FarePreferences;
  cabinTypes = Dictionary.CabinTypes;


  constructor(private router: Router,
              private ls: LocalStorageService) { }

  ngOnInit(): void {
   this.webOffersData = this.webOffersData?.filter(offer => offer.email === this.ls.email)
     .sort((a, b) => new Date(b.search_date).getTime() - new Date(a.search_date).getTime());

   this.showColumns();
  }

  searchSelectedOffer(offer) {
    this.emitSearchOffer.emit(offer);
    this.emitDismiss.emit(true);
  }

  removeOffer(id, searchType ) {
    this.emitRemoveOffer.emit({id, searchType});
  }

  showColumns() {
    this.showColumn.preferences = !!this.webOffersData.find(offer => offer.preferences || (offer.preset?.preference && offer.preset.preference[0]?.title !== 'No Preference'));
    this.showColumn.specialDiscounts = !!this.webOffersData.find(offer => Object.keys(offer.special_discounts).length);
    this.showColumn.preset = !!this.webOffersData.find(offer => offer.preset);
  }

}
